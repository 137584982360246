import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
import { fitNameToUrl, newFormatDate } from "../../utility/utility";
import { useTranslation } from "react-i18next";
const { forumNameConfig } = require("../../config");

const Review = () => {
    const { t } = useTranslation();
    const [topics, setTopics] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await axios.get("/api/topics", {
                    params: { count: 4 }, // adjust the count as needed
                });
                setTopics(response.data);
            } catch (error) {
                console.error("Failed to fetch topics:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTopics();
    }, []);

    if (loading) {
        return <div>{t("Loading")}</div>;
    }

    return (
        <div>
            <div
                className={`${styles.reviewItemContainer} ${styles.noPadding}`}
            >
                <div className={`${styles.big_item} ${styles.header}`}>
                    {t("Title")}
                </div>
                <div className={`${styles.small_item} ${styles.header}`}>
                    {t("Clicks")}
                </div>
                <div className={`${styles.time_item} ${styles.header}`}>
                    {t("PostDate")}
                </div>
            </div>

            {topics.map((topic) => {
                const { _id, forum, name, viewNum, updatedAt } = topic;
                const forumId = forum;
                const updatedDate = newFormatDate(new Date(updatedAt));

                return (
                    <div key={name} className={styles.reviewItemContainer}>
                        <div className={styles.big_item}>
                            <Link
                                className={styles.link}
                                to={{
                                    pathname: `/${forumNameConfig}/${fitNameToUrl(
                                        name
                                    )}/${forumId}/${_id}`,
                                    search: "",
                                    hash: "",
                                    state: {
                                        forumId: forumId,
                                        topicId: _id,
                                        title: name,
                                        updatedAt: updatedAt,
                                    },
                                }}
                            >
                                {name}
                            </Link>
                        </div>
                        <div className={styles.small_item}>{viewNum}</div>
                        <div className={styles.time_item}>{updatedDate}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default Review;
