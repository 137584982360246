import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    ca: {
        translation: {
            PDFDownload: "PDF download",
            Document: "Document",
            LegalName: "Legal Name",
            ACAN: "Alberta Corporate Access Number",
            BN: "Business Number",
            BusineseInfo: "Businese Info",
            ForgotPassword: "Reset Password",
            NotFoundMessage: "404! Page not found.",
            YoutubeHomeTitle: "Calgary Travel Agency, C&K Tour",
            YoutubeHomeText:
                "Traveling with us will be even more meaningful and memorable.<br>",
            Banff: "Banff",
            BanffDescription:
                "Banff is famous for its majestic mountains and beautiful lakes, where you can see various wild animals such as deer and bears.<br>Create unforgettable moments in this world-renowned travel destination.",
            Rockies: "Rockies",
            RockyDescription:
                "Rocky Mountains in Alberta and British Columbia are renowned for their majestic mountains, beautiful lakes, and natural hot springs.<br>Explore this world-famous destination, where you can encounter various wildlife.",
            Jasper: "Jasper",
            JasperDescription:
                "Located in the heart of the Rocky Mountains, Jasper National Park is one of Canada's proud treasures.<br>Marvel at deep blue glacier lakes and majestic mountain ranges as you stroll through diverse and lush vegetation.<br>Additionally, encounter various wildlife such as bears, deer, wolves, and mountain sheep for an unforgettable experience.",
            Kananaskis: "Kananaskis",
            KananaskisDescription:
                "A heavenly playground within the Rocky Mountains.<br>Kananaskis in Alberta offers beautiful mountain landscapes, clear lakes, and various outdoor activities.",
            Calgary: "Calgary",
            CalgaryDescription:
                "Calgary, the largest city in Alberta, Canada, hosted the first Winter Olympics in 1988 and ranks 5th in population in Canada.<br>Thanks to the warm Chinook winds from the Rocky Mountains, winters are relatively mild compared to other regions in Canada.<br>Calgary is also famous for its Stampede, traditional music festivals, Lilac Festival, and Caribbean Festival.",
            Mexico: "Mexico",
            MexicoDescription:
                "Enjoy the beautiful landscapes and rich culture of Mexico.",
            Yellowknife: "Yellowknife",
            YellowknifeDescription:
                "Yellowknife, the capital of the Northwest Territories in Canada, is recognized by NASA as the world's best aurora observation site.<br>Surrounded by mountains within a thousand kilometers, you can enjoy the auroras without obstruction.<br>Experience the best ground-level aurora travel in Yellowknife.",
            Asia: "Asia",
            AsiaDescription:
                "Experience the allure of Asia - where every journey is a fusion of tradition and innovation, nature and urbanity, creating a travel experience like no other.",
            AccountInfo: "Account Info",
            IntroCEO: "About Us",
            ContactUs: "Contact Us",
            Login: "Login",
            SimpleSignup: "Signup",
            Logout: "Logout",
            Profile: "Profile",
            Signup: "Signup",
            EmailOrUserName: "Username or Email",
            Password: "Password",
            Email: "Email",
            Username: "Username",
            UsernameReq:
                "1-50 characters. Only numbers and letters are allowed.",
            PasswordReq:
                "3-20 characters. Numbers, letters, and _!@#$%^&* are allowed.",
            CEOPhone: "Inquiry/Cell Number",
            TripDays: "Travel Period",
            MinPerson: "Minimum Persons",
            Route: "Route",
            Price: "Price",
            TripInquire: "Reservation Inquiry",
            WriteTripInquire: "Write Reservation Inquiry",
            WriteNew: "Write New",
            TripBoardHeader: "Reservation Inquiry/Travel Reviews and Q&A Board",
            Category: "Category",
            Topic: "Topic",
            Replies: "Replies",
            Clicks: "Clicks",
            PostDate: "Post Time",
            NoPost: "No posts available.",
            BoardHeader: "Product Description",
            LoginFailed: "Login failed. Please try again.",
            Days: " day(s)",
            Persons: " person(s)",
            PlzLogin: "Please login first.",
            WriteLimit: "Can write 10 per day to prevent spam.",
            WriteContent: "Please write at least a title and content.",
            WriteBoard: "Write new",
            Title: "Title",
            Post: "Post",
            Close: "Close",
            Tour: "Tour",
            Home: "Home",
            RegFailed: "Registration failed. Please try again later.",
            NoTourPackage:
                "We are preparing travel packages for this location.",
            Kakao: "Add Kakao ch. via QR",
            Deleted: "Deleted successfully.",
            NotDeleted: "Could not be deleted. Try again.",
            Updated: "Updated successfully.",
            NotUpdated: "Could not be updated. Try again.",
            Update: "Modify",
            Delete: "Delete",
            AddReply: "Add Reply",
            GeneralError: "Error occured.Please try again.",
            UnknownError: "Unknown error occured.Please try again.",
            LoggedOut: "You have been logged out.",
            ToHome: "Home",
            ReplyAdded: "Your reply has been added.",
            Cancel: "Cancel",
            ConfirmDeletion: "Confirm Deletion",
            DeletionMsg: "Are you sure you want to delete this?",
            Confirm: "Confirm",
            WriteMin: "Minimum 10 characters are required.",
            Product: "Product",
            Essential: "Essentials",
            Safety: "Safety/Precautions",
            Inexcluded: "Inclusions/Exclusions",
            RouteDetail: "Travel itinerary",
            Included: "Inclusions",
            Excluded: "Exclusions",
            Loading: "Loading...",
            NotLoggedinBoardMsg:
                "When a post is made, an email is sent to the admin, and when the admin replies, the reply is sent to the email used at the time of registration.",
            UseEmailCorrectly:
                "When you leave a post, an email is sent to the admin, and if the admin replies, the reply will be sent to {{email}}.",
            ReplyEmail:
                "When a post is made, an email is sent to the thread's initial poster and the admin.",
            Inquire: "Inquire",
            TobeNego: "Negotiable",
            YoutubeText:
                "Want to share precious memories with loved ones or cherish them for a long time? Our travel agency creates YouTube videos for FREE!",
            Discounted: "Discounted",
            Recommended: "Recommended",
            ContactUsTitle: "Contact Us",
            ContactUsDescription:
                "Do you have any questions? We will respond as quickly as possible.",
            RealTimeConsultation:
                "For real-time consultation, please use Kakao Talk.",
            SpamPrevention: "Spam Prevention",
            EnterNumericAnswer: "Enter the numeric answer",
            IncorrectAnswer: "Incorrect answer",
            PrivatePost: "Private Post",
            HideContentInBoard: "Hide content in the board",
            Sender: "Sender",
            Subject: "Subject",
            MinCharacters: "Must be at least {{count}} characters long.",
            Content: "Content",
            SpamPreventionLabel: "Spam Prevention",
            SpamPreventionPlaceholder: "Enter the result",
            SpamPreventionIncorrect: "Incorrect answer",
            ConsentLabel: "Consent",
            ConsentAgreeText:
                "Agree to receive travel information, events, and coupons",
            ConsentNotice:
                "For secure transactions, notices about travel product usage, terms, and service changes will be sent regardless of consent.",
            ConsentAllAgreeText: "Agree to all terms and privacy policy",
            ConsentTermsAgreeText: "Agree to Terms and Conditions",
            ConsentPrivacyAgreeText: "Agree to Privacy Policy",
            SignUpButton: "Sign Up",
            RegisterTitle: "Sign Up",
            RegisterDescription:
                "Create a new account to access exclusive features by C&K Tour, Ltd.",
            UsernameLabel: "Login ID",
            UsernamePlaceholder: "1-10 characters",
            UsernameInUse: "Username is already in use",
            UsernameSpecialChar: "Username cannot contain special characters",
            EmailLabel: "Email",
            EmailPlaceholder: "Enter your email",
            EmailInUse: "Email is already in use",
            PasswordLabel: "Password",
            PasswordPlaceholder: "Minimum 6 characters",
            ConfirmPassword: "Confirm Password",
            PasswordMismatch: "Passwords do not match",
            VerifyEmailSubject: "Email Verification",
            EmailSentSuccess:
                "An email has been sent to {{email}}. Click on the 'Verify' link in the email to complete the registration. If you don't receive the email within 5 minutes, please check your Junk folder. If you still haven't received it, please contact us.",
            EmailSentError:
                "Email was not sent to {{email}}. Please check your email and try signing up again.",
            ErrorMessage:
                "An error occurred. Please contact us, and we will resolve it as soon as possible.",
            Required: "Required",
            EMAIL_VERIFICATION_TITLE: "Email Verification",
            EMAIL_VERIFICATION_DESCRIPTION1:
                "To complete the registration, you need to verify your email.",
            EMAIL_VERIFICATION_DESCRIPTION2:
                "Join C&K Tour for the ability to post on the forum and enjoy various travel-related benefits.",
            EMAIL_VERIFICATION_SUCCESS:
                "Registration completed! Please log in.",
            EMAIL_VERIFICATION_FAILURE:
                "Email verification failed. Please contact support.",
            EMAIL_DOES_NOT_EXIST: "Email does not exist.",
            EMAIL_ALREADY_VERIFIED: "Email already verified.",
            INCORRECT_CODE: "Incorrect 5-digit code.",
            INTERNAL_SERVER_ERROR:
                "Internal server error. Please contact support.",
            EMAIL_LABEL: "Email",
            CODE_LABEL: "Code",
            VERIFY_BUTTON_TEXT: "Verify",
            VERIFY_EMAIL_SENT_LOADING_TEXT: "Sending verification email...",
            VERIFY_EMAIL_SENT_LOADING_ALT: "Loading",
            EmailFailed:
                "Failed to send email. Please try again or consider KakaoTalk live chat support.",
            ConfirmEmail: "Please confirm your email address.",
            EmailFailedGenericError:
                "Failed to send email. Please try again or consider KakaoTalk live chat support. Error:",
            EmailSent: "Email has been sent.",
            TandC: "Terms and Conditions",
            Privacy: "Privacy Policy",
            Attention: "Reference/Cautionary Notes",
            Hire: "Careers",
            TourTitle: "Trip Information",
            Show: "Show",
            Hide: "Hide",
            ShareProduct: "Share",
            TourName: "Tour Name",
            TourDesc: "Tour Description",
            InteractAddress: "E-transfer (Interact)",
            Interact: "info@cnktour.com",
            InteractMsg:
                "Deposit to info@cnktour.com using E-transfer (Interact) to confirm your reservation.",
            OnePersonRule: "Remarks",
            OnePersonRuleDesc:
                "Below product price is based on a minimum of 4 persons. Additional charges apply for less than 3 travellers or additional room requests, typically CAD$200 per day unless it is a day trip. Please inquire if this applies to you.",
            JoinTitle: "Anyone up for a trip together?",
            JoinDesc:
                "For reasons such as saving on accommodation costs, please contact us at <a href='mailto:info@cnktour.com'>info@cnktour.com</a> if you would like to join the schedule below. (The tour will proceed if the minimum number of participants is met.)",
            JoinMale: "Male",
            JoinFemale: "Female",
            JoinStart: "Start Date",
            JoinEnd: "End Date",
            JoinRemarks: "Remarks",
            PerPerson: "person",
            HJKim: "Kim Hee Jung",
            HJKimRole: "Co-CEO/Travel Guide",
            HJKimRemarks: "Kim Hee Jung guides most tours.",
            Kai: "Kai Lee",
            KaiRole: "Co-CEO/Web/Travel Guide",
            KaiRemarks:
                "Lived in Canada since 1999/University of Calgary graduate",
            Lym: "Lym Lee",
            LymRole: "Travel Guide",
            LymRemarks: "Handles women-only tours only upon request.",
            IHKim: "Ilho Cho",
            IHKimRole: "Program Coordinator",
            JEKang: "JungEun Kang",
            JEKangRole: "Travel Guide",
            SMLee: "SuMin Lee",
            SMLeeRole: "Travel Counseller",
            DEHan: "Doui Han",
            DEHanRole: "Travel Guide",
            DEHanRemarks:
                "Scuba Diving Instructor. Snowboard Instructor. Freediving Lv2 certificate holder.",
            beneficiaryBank: "Beneficiary's Bank",
            beneficiaryBankAddress: "Beneficiary Bank Address",
            beneficiaryName: "Beneficiary Name",
            beneficiaryAddress: "Beneficiary Address",
            transitNumber: "Transit Number",
            institutionCode: "Institution (Bank) Code",
            accountNumber: "Account Number",
            swiftBIC: "SWIFT/BIC",
            accountType: "Account Type",
            LinkToTour: "Check out this travel product",
            TourReview: "Tour Review",
        },
    },
    kr: {
        translation: {
            PDFDownload: "PDF 다운로드",
            Document: "서류",
            LegalName: "사업자명",
            ACAN: "알버타 기업번호",
            BN: "사업자 등록번호",
            BusineseInfo: "비즈니스 정보",
            ForgotPassword: "비밀번호 재설정하기",
            NotFoundMessage: "404! 주소를 찾을수 없습니다.",
            YoutubeHomeTitle: "캘거리 최고의 여행사 C&K투어",
            YoutubeHomeText:
                "저희와 함께라면 더욱 뜻깊고 기억에 남는 만족스러운 여행이 될 것입니다.",
            Rockies: "록키(Rockies)",
            RockyDescription:
                "캐나다 알버타와 브리티시 컬럼비아주의 록키는 웅장한 산과 아름다운 호수가 어우러진 자연 경관, 온천으로 유명합니다.<br>사슴과 곰 등 여러 종의 야생 동물을 볼 수 있는 세계적인 여행지에서 잊지 못할 시간을 만들어보세요.",
            Banff: "밴프(Banff)",
            BanffDescription:
                "밴프는 웅장한 산과 아름다운 호수가 어우러진 자연 경관으로 유명합니다.<br>사슴과 곰 등 여러 종의 야생 동물을 볼 수 있는 세계적인 여행지에서 잊지 못할 시간을 만들어보세요.",
            Jasper: "재스퍼(Jasper)",
            JasperDescription:
                "로키 산맥의 중심부에 자리한 재스퍼 국립 공원은 캐나다의 자랑스러운 보물 중 하나입니다.<br>깊고 푸른 빙하 호수와 웅장한 설산에 감탄하며 다양하고 무성한 식물 사이를 거닐 수 있습니다.<br>여기에 더해, 곰, 순록, 늑대, 산양과 같은 다양한 야생동물이 여러분에게 잊지 못할 경험을 선사할 것입니다.",
            Kananaskis: "카나나스키스(Kananaskis)",
            KananaskisDescription:
                "록키 산맥 속의 천혜의 놀이터, 알버타주 카나나스키스.<br>원시림에 둘러싸인 이곳은 아름다운 산맥 풍경, 맑은 호수, 자연을 사랑하는 이들과 모험을 즐기는 이들을 위한 다양한 야외 활동을 제공합니다.",
            Calgary: "캘거리(Calgary)",
            CalgaryDescription:
                "캐나다에서 최초로 1988년 동계 올림픽이 개최된 캘거리는 앨버타 주에서 제일 큰 도시이며 인구수로 캐나다에서 5위를 차지하고 있습니다.<br>록키산맥으로부터 시눅(Chinook)이라는 따듯한 바람이 불어와서 캐나다 다른 지역보다 상대적으로 겨울이 더 따듯합니다.<br>스탬피드, 전통 음악 축제, 라일락 축제, 그리고 캐리비안 축제 등 여러 유명한 축제로 유명합니다.",
            Mexico: "멕시코",
            MexicoDescription: "멕시코의 아름다운 풍경과 문화를 즐겨보세요.",
            Yellowknife: "옐로나이프(Yellowknife)",
            YellowknifeDescription:
                "캐나다 노스웨스트 준주의 주도 옐로나이프는 미국 항공우주국 NASA가 선정한 세계 최고의 오로라 관측지입니다.<br>연중 오로라가 출몰하는 오로라 오벌(Oval) 지역에 속하고, 사방 천 킬로미터 이내에 산맥이 존재하지 않아 시야의 방해 없이 오로라를 즐기실 수 있습니다.<br>옐로나이프에서 지상 최고의 오로라 여행을 만끽해보세요.",
            Asia: "아시아",
            AsiaDescription:
                "아시아의 매력을 체험하세요 - 모든 여정이 전통과 혁신, 자연과 도시의 융합으로 이루어진, 그 어떤 다른 여행 경험과도 다른 특별한 경험을 창조합니다.",
            AccountInfo: "계좌정보",
            Inquire: "문의바랍니다",
            ContactUs: "문의하기",
            IntroCEO: "회사소개",
            Login: "로그인",
            SimpleSignup: "간편가입",
            Logout: "로그아웃",
            Profile: "프로필",
            Signup: "가입하기",
            EmailOrUserName: "아이디 또는 이메일",
            Password: "패스워드",
            Email: "이메일",
            Username: "사용자 이름",
            UsernameReq: "1~50자. 숫자와 알파벳만 허용됩니다.",
            PasswordReq: "3~20자. 숫자, 알파벳과 _!@#$%^&*가 허용됩니다.",
            CEOPhone: "문의전화/대표전화",
            TripDays: "여행기간",
            MinPerson: "최소인원",
            Route: "여정",
            Price: "가격",
            TripInquire: "예약문의",
            WriteTripInquire: "예약문의 글쓰기",
            WriteNew: "새 글쓰기",
            TripBoardHeader: "예약문의/여행후기 및 질문게시판",
            Category: "분류",
            Topic: "토픽",
            Replies: "답글수",
            Clicks: "클릭수",
            PostDate: "등록시간",
            NoPost: "게시글이 없습니다.",
            BoardHeader: "여행 상품 설명",
            LoginFailed: "로그인에 실패했습니다. 다시 시도해주세요.",
            Days: "일",
            Persons: "명",
            PlzLogin: "로그인 하세요.",
            WriteContent: "제목과 내용을 써 주세요.",
            WriteLimit:
                "스팸을 막기위해 하루에 최대 10개만 글을 쓸 수 있게하고 있으니 양해부탁드립니다.",
            WriteBoard: "게시판 글쓰기",
            Title: "제목",
            Post: "게시하기",
            Close: "닫기",
            Tour: "투어",
            Home: "홈페이지",
            RegFailed: "등록에 실패하였습니다. 다시시도해보세요.",
            NoTourPackage: "이 지역 여행상품을 준비중입니다.",
            Kakao: "카톡상담 QR코드",
            Deleted: "삭제되었습니다.",
            NotDeleted: "삭제되지 않았습니다. 다시 시도해보세요.",
            Updated: "업데이트 되었습니다.",
            NotUpdated: "업데이트되지 않았습니다. 다시 시도해보세요.",
            Update: "수정",
            Delete: "삭제",
            AddReply: "답글달기",
            GeneralError: "문제가 생겼습니다. 다시 시도해주세요.",
            UnknownError: "예상치 못한 문제가 생겼습니다. 다시 시도해주세요.",
            LoggedOut: "로그아웃 되었습니다.",
            ToHome: "홈으로 가기",
            ReplyAdded: "답글이 등록되었습니다.",
            Cancel: "취소",
            ConfirmDeletion: "삭제확인",
            DeletionMsg: "정말 이 글을 지우시겠습니까?",
            Confirm: "확인",
            WriteMin: "10글자 미만의 글은 허용하지 않습니다.",
            Product: "상품소개",
            Essential: "유의사항",
            Safety: "안전/주의사항",
            Inexcluded: "포함/불포함",
            RouteDetail: "여행 일정",
            Included: "포함사항",
            Excluded: "불포함사항",
            Loading: "로딩중...",
            NotLoggedinBoardMsg:
                "글을 남기면 관리자에게 이메일이 전송되고, 관리자가 답변을 달면 가입시 사용한 이메일로 답변이 전송됩니다.",
            UseEmailCorrectly:
                "글을 남기면 관리자에게 이메일이 전송되고, 관리자가 답변을 달면 {{email}}로 답변이 전송됩니다.",
            ReplyEmail:
                "답변은 이메일로 이 게시물 초기 작성자와 관리자에게 전송됩니다.",
            TobeNego: "협의가능",
            YoutubeText:
                "여행의 소중한 추억을 소중한 사람들과 나누거나 오래도록 간직하고 싶으신가요? 저희 여행사는 무료로 Youtube영상을 만들어 드립니다.",
            Discounted: "할인상품",
            Recommended: "추천상품",
            ContactUsTitle: "문의하기",
            ContactUsDescription:
                "궁금한것이 있으신가요? 최대한 빠르게 답변드리겠습니다.",
            RealTimeConsultation:
                "실시간 상담은 카카오 상담하기를 이용해 주세요.",
            SpamPrevention: "스팸방지",
            EnterNumericAnswer: "숫자로 정답을 써주세요",
            IncorrectAnswer: "정답이 아닙니다",
            PrivatePost: "비밀글",
            HideContentInBoard: "게시판에서 내용숨기기",
            Sender: "보내는 사람",
            Subject: "제목",
            MinCharacters: "최소 {{count}}자 이상이어야합니다.",
            Content: "내용",
            SpamPreventionLabel: "스팸 방지",
            SpamPreventionPlaceholder: "결과를 입력하세요",
            SpamPreventionIncorrect: "정답이 아닙니다",
            ConsentLabel: "수신 동의",
            ConsentAgreeText: "여행 정보, 이벤트 및 쿠폰 수신에 동의합니다",
            ConsentNotice:
                "안전한 거래를 위해 여행 상품 이용 사항, 약관 및 서비스 변경에 대한 공지는 수신 동의 여부와 상관없이 발송됩니다.",
            ConsentAllAgreeText:
                "이용 약관 및 개인 정보 수집 및 이용에 모두 동의합니다",
            ConsentTermsAgreeText: "이용 약관에 동의합니다 (필수)",
            ConsentPrivacyAgreeText:
                "개인 정보 수집 및 이용에 동의합니다 (필수)",
            SignUpButton: "가입하기",
            RegisterTitle: "가입하기",
            RegisterDescription:
                "지금 C&K 투어에 가입하셔서 여행과 관련된 다양한 혜택을 누리세요.",
            UsernameLabel: "아이디(사용자 이름)",
            UsernamePlaceholder: "1~10자의 한글/영어/숫자",
            UsernameInUse: "사용자 이름이 이미 사용 중입니다",
            UsernameSpecialChar:
                "사용자 이름에는 특수 문자를 포함할 수 없습니다",
            EmailLabel: "이메일",
            EmailPlaceholder: "이메일을 입력하세요",
            EmailInUse: "이메일이 이미 사용 중입니다",
            PasswordLabel: "비밀번호",
            PasswordPlaceholder: "최소 6자 비밀번호",
            ConfirmPassword: "비밀번호 확인",
            PasswordMismatch: "비밀번호가 일치하지 않습니다",
            VerifyEmailSubject: "이메일 인증",
            EmailSentSuccess:
                "{{email}}로 이메일이 전송되었습니다. 이메일의 '인증하기' 링크를 클릭하여 등록을 완료하세요. 이메일이 5분 내에 도착하지 않으면 Junk 폴더도 확인하세요. 아직 받지 못했다면 문의하기로 문의하세요.",
            EmailSentError:
                "{{email}}로 이메일이 전송되지 않았습니다. 이메일을 확인하고 다시 가입을 신청하세요.",
            ErrorMessage:
                "오류가 발생했습니다. 문의주시면 최대한 빨리 해결해 드리겠습니다.",
            Required: "필수",
            EMAIL_VERIFICATION_TITLE: "이메일 인증하기",
            EMAIL_VERIFICATION_DESCRIPTION1:
                "회원가입을 마치려면 이메일을 인증해야합니다.",
            EMAIL_VERIFICATION_DESCRIPTION2:
                "C&K 투어에 가입하시면 게시판에 글을 남기실 수 있으며 여행과 관련된 다양한 혜택을 드립니다.",
            EMAIL_VERIFICATION_SUCCESS:
                "회원가입이 완료되었습니다! 로그인 해주세요.",
            EMAIL_VERIFICATION_FAILURE:
                "이메일 인증에 실패했습니다. 문의바랍니다.",
            EMAIL_DOES_NOT_EXIST: "이메일이 존재하지 않습니다.",
            EMAIL_ALREADY_VERIFIED: "이미 인증된 이메일입니다.",
            INCORRECT_CODE: "5자리 숫자 코드가 다릅니다.",
            INTERNAL_SERVER_ERROR: "서버 오류입니다. 문의바랍니다.",
            EMAIL_LABEL: "이메일",
            CODE_LABEL: "코드",
            VERIFY_BUTTON_TEXT: "인증하기",
            VERIFY_EMAIL_SENT_LOADING_TEXT: "인증 메일 전송 중...",
            VERIFY_EMAIL_SENT_LOADING_ALT: "로딩 중",
            EmailFailed:
                "이메일 전송에 실패했습니다. 다시 시도해 보거나 카카오 실시간 상담하기를 추천드립니다.",
            ConfirmEmail: "이메일 주소를 확인바랍니다.",
            EmailFailedGenericError:
                "이메일 전송에 실패했습니다. 다시 시도해 보거나 카카오 실시간 상담하기를 추천드립니다. 에러:",
            EmailSent: "이메일이 전송되었습니다.",
            TandC: "이용약관",
            Privacy: "개인정보 취급방침",
            Attention: "유의사항",
            Hire: "채용정보",
            TourTitle: "여행정보",
            Show: "보이기",
            Hide: "숨기기",
            ShareProduct: "공유",
            TourName: "여행 이름",
            TourDesc: "간단한 설명",
            InteractAddress: "E-transfer (Interact) 주소",
            Interact: "info@cnktour.com",
            InteractMsg:
                "E-transfer (Interact)를 이용해 info@cnktour.com로 여행비용을 지불하면 예약이 확정됩니다.",
            OnePersonRule: "참고",
            OnePersonRuleDesc:
                "당일 여행은 인원에 상관없이 추가비용은 없습니다. 하지만, 1박이상 숙박하는 상품 가격은 최소인원 4명 이상을 기준으로 하며, 3명이하 여행이나 별도 객실 추가 시 추가 비용이 발생합니다. 추가 비용은 보통 하루당 CAD$200입니다. 해당 고객님은 예약 전 문의바랍니다.",
            JoinTitle: "같이 여행하실분?",
            JoinDesc:
                "숙박비 절약등의 이유로 아래 일정에 함께 하시려면 <a href='mailto:info@cnktour.com'>info@cnktour.com</a>로 문의 바랍니다. (최소인원이 충족되면 투어는 진행합니다.)",
            JoinMale: "남자 인원수",
            JoinFemale: "여자 인원수",
            JoinStart: "희망 출발일",
            JoinEnd: "희망 도착일",
            JoinRemarks: "특이사항",
            PerPerson: "1인",
            HJKim: "김희정",
            HJKimRole: "공동대표/대표 가이드",
            HJKimRemarks: "대부분의 투어는 김희정대표가 직접 진행합니다.",
            Kai: "Kai",
            KaiRole: "공동대표/웹개발/가이드",
            KaiRemarks: "99년 이후 캐나다거주/캘거리대 컴퓨터공학과졸업/군필",
            Lym: "이림",
            LymRole: "가이드",
            LymRemarks: "여성투어만 요청시 진행합니다.",
            IHKim: "조일호",
            IHKimRole: "프로그램 코디네이터",
            JEKang: "강정은",
            JEKangRole: "가이드",
            SMLee: "이수민",
            SMLeeRole: "여행 카운셀러",
            DEHan: "한도의",
            DEHanRole: "가이드",
            DEHanRemarks:
                "스쿠버 다이빙, 스노보딩, 프리다이빙 레벨2 자격증 소지. 군필.",
            beneficiaryBank: "수취은행",
            beneficiaryBankAddress: "수취은행 주소",
            beneficiaryName: "수취인 이름",
            beneficiaryAddress: "수취인 주소",
            transitNumber: "트랜짓 넘버",
            institutionCode: "기관 코드 (은행 코드)",
            accountNumber: "계좌 번호",
            swiftBIC: "SWIFT/BIC",
            accountType: "계좌 종류",
            LinkToTour: "해당 여행상품 구경하기",
            TourReview: "여행 후기",
        },
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "ca", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
